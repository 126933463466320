define("@aspen/aspen-utils/aspen-mailer/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    fetchService: (0, _service.inject)('aspen-fetch'),
    send: function (payload) {
      let service = this;
      let fetchService = service.get('fetchService');
      let domain = service.config.environment === 'production' ? 'storage.api.aspensquare.com' : 'dev-storage-api.aspensquare.com';
      let mailEndpoint = `https://${domain}/queues/email`;
      return fetchService.post(mailEndpoint, {
        body: payload
      });
    }
  });
  _exports.default = _default;
});