define("@aspen/aspen-utils/document/service", ["exports", "@ember/service", "uuid"], function (_exports, _service, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DocumentService = (_class = class DocumentService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "queryBuilder", _descriptor, this);
      _initializerDefineProperty(this, "aspenFetch", _descriptor2, this);
    }
    readFile(elementId) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        const fileInput = document.getElementById(elementId);
        if (!fileInput) {
          resolve(undefined);
        }
        const fileList = fileInput.files;
        const file = fileList[0];
        if (!fileList.length) {
          resolve(undefined);
        } else {
          reader.onload = function (evt) {
            resolve({
              name: file.name,
              contents: evt.target.result
            });
          };
          reader.onerror = () => {
            reader.abort();
            reject(new DOMException('Problem parsing file.'));
          };
          reader.readAsArrayBuffer(file);
        }
      });
    }
    async upload(elementId) {
      const service = this;
      const file = await service.readFile(elementId);
      const contentTypeMap = {
        pdf: 'application/pdf'
      };
      if (!file) {
        throw new Error('File Not Found');
      }
      const fileNameSegments = file.name.split('.');
      const extension = fileNameSegments[fileNameSegments.length - 1];
      const fileName = `${(0, _uuid.v4)()}.${extension}`;
      const uri = `${service.config.storage.baseApiURL}/blobs/binary/${service.config.storage.container}?${service.queryBuilder.buildQuery({
        fileName: fileName
      })}`;
      const options = {
        headers: {
          'content-type': contentTypeMap[extension] ? contentTypeMap[extension] : 'application/octet-stream'
        },
        body: file.contents
      };
      const r = await service.aspenFetch.post(uri, options);
      return r.response.headers.map['location'];
    }
    open(data, fileName, format) {
      const blob = new Blob([data], {
        type: format
      });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      } else {
        navigator.msSaveOrOpenBlob(blob, fileName);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "queryBuilder", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "aspenFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = DocumentService;
});