define("ember-welcome-page/components/welcome-page", ["exports", "@ember/application", "@ember/version", "@ember/object", "@ember/component", "ember-welcome-page/templates/components/welcome-page"], function (_exports, _application, _version, _object, _component, _welcomePage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _welcomePage.default,
    isCurrent: (0, _object.computed)(function () {
      let stableRegex = /^\d+\.\d+\.\d+$/;
      return !stableRegex.test(_version.VERSION);
    }),
    canAngleBracket: (0, _object.computed)(function () {
      return true;
    }),
    isModuleUnification: (0, _object.computed)(function () {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config && config.isModuleUnification;
    }),
    rootURL: (0, _object.computed)(function () {
      let config = (0, _application.getOwner)(this).factoryFor('config:environment');
      if (config) {
        return config.class.rootURL;
      } else {
        return '/';
      }
    }),
    emberVersion: (0, _object.computed)('isCurrent', function () {
      let isCurrent = (0, _object.get)(this, 'isCurrent');
      if (isCurrent) {
        return 'current';
      } else {
        let [major, minor] = _version.VERSION.split(".");
        return `${major}.${minor}.0`;
      }
    })
  });
  _exports.default = _default;
});