define("aspen-utils/cookie-storage-provider/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    cookies: (0, _service.inject)('cookies'),
    getItem: function (key) {
      var item = this.get('cookies').read(key);
      return item ? JSON.parse(item) : null;
    },
    setItem: function (key, data) {
      this.get('cookies').write(key, JSON.stringify(data));
    },
    removeItem: function (key) {
      this.get('cookies').clear(key);
    }
  });
  _exports.default = _default;
});