define('@aspen/aspen-search-controls/components/results-pager/component', ['exports', '@aspen/aspen-search-controls/components/results-pager/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,

        currentPage: 1,

        pages: Ember.computed('top', 'count', 'skip', function () {
            let component = this;
            let defaultPageCount = 10;
            let pageCount = Math.ceil(parseInt(component.count) / parseInt(component.top));
            let pages = [];
            let lowerBound = 1;
            let upperBound = pageCount > defaultPageCount ? defaultPageCount : pageCount;

            for (let p = lowerBound; p <= upperBound; p++) {
                pages.push({
                    value: p,
                    active: p === component.currentPage
                });
            }

            return pages;
        }),

        actions: {
            next: function () {
                this.onNext();
            },

            previous: function () {
                this.onPrevious();
            },

            first: function () {
                this.onFirst();
            },

            last: function () {
                this.onLast();
            },

            changePage: function (value) {
                let component = this;

                component.set('currentPage', value);
                component.onChangePage(value);
            }
        }
    });
});