define("@aspen/aspen-navigation-controls/components/site-navigation/navigation-breadcrumb/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S7PjwGtp",
    "block": "[[[41,[48,[30,2]],[[[1,\"    \"],[10,0],[14,0,\"hidden-xs\"],[12],[1,\"\\n        \"],[10,\"ol\"],[14,0,\"breadcrumb nav navbar-nav\"],[14,5,\"background-color: transparent;\"],[12],[1,\"\\n            \"],[10,\"li\"],[14,0,\"active\"],[12],[1,[30,1]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@applicationName\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@aspen/aspen-navigation-controls/components/site-navigation/navigation-breadcrumb/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});