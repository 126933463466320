define("aspen-utils/helpers/format-phone", ["exports", "@ember/component/helper", "@ember/template", "@ember/utils"], function (_exports, _helper, _template, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var formatPhone = function (params /*, hash*/) {
    var number = params[0];
    if ((0, _utils.isBlank)(number) || number === null || number.length != 10) {
      return new _template.htmlSafe('');
    }
    return new _template.htmlSafe(number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"));
  };
  var _default = (0, _helper.helper)(formatPhone);
  _exports.default = _default;
});