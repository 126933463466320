define("aspen-utils/aspen-local-storage/service", ["exports", "@ember/object", "@ember/service"], function (_exports, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    cookies: (0, _service.inject)('cookie-storage-provider'),
    init: function () {
      var service = this;
      this._super(...arguments);
      service.storageProvider = service.get('_isLocalStorageAvailable') ? localStorage : service.get('cookies');
    },
    _isLocalStorageAvailable: (0, _object.computed)(function () {
      try {
        var localStorageTestKey = 'local-storage-test-key';
        localStorage.setItem(localStorageTestKey, true);
        localStorage.removeItem(localStorageTestKey);
        return true;
      } catch (e) {
        return false;
      }
    }),
    getItem: function (key) {
      var item = this.storageProvider.getItem(key);
      return item ? JSON.parse(item) : null;
    },
    setItem: function (key, data) {
      this.storageProvider.setItem(key, JSON.stringify(data));
    },
    removeItem: function (key) {
      this.storageProvider.removeItem(key);
    }
  });
  _exports.default = _default;
});