define("@aspen/aspen-navigation-controls/components/section-navigation/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "B/Oo5E5X",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@aspen/aspen-navigation-controls/components/section-navigation/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});