define("aspen-utils/registration/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //TODO: Break out into own repo
  var _default = _service.default.extend({
    fetchService: (0, _service.inject)('aspen-fetch'),
    registerUser: function (data) {
      var service = this;
      var fetchService = service.get('fetchService');
      var url = service.config.identity.baseApiURL + '/account/register';
      return fetchService.post(url, {
        body: data
      });
    },
    getExternalLogins: function () {
      var service = this;
      var fetchService = service.get('fetchService');
      var url = service.config.identity.baseApiURL + '/account/externalLogins';
      return fetchService.fetch(url);
    },
    sendResetPasswordEmail: function (email) {
      var service = this;
      var fetchService = service.get('fetchService');
      var url = service.config.identity.baseApiURL + '/account/forgotPassword?email=' + encodeURIComponent(email) + '&resetPasswordUrl=' + encodeURIComponent(service.config.callbackUrls.resetPasswordUrl);
      return fetchService.fetch(url);
    },
    resetPassword: function (data) {
      var service = this;
      var fetchService = service.get('fetchService');
      var url = service.config.identity.baseApiURL + '/account/resetPassword';
      return fetchService.post(url, {
        body: data
      });
    }
  });
  _exports.default = _default;
});