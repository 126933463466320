define("@aspen/aspen-navigation-controls/components/site-navigation/navigation-menu/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YtYyui0G",
    "block": "[[[41,[48,[30,3]],[[[1,\"    \"],[10,0],[14,0,\"hidden-xs hidden-sm\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"nav navbar-nav navbar-right\"],[12],[1,\"\\n            \"],[18,3,null],[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"                \"],[10,\"li\"],[14,0,\"dropdown\"],[12],[1,\"\\n                    \"],[10,3],[14,6,\"#\"],[14,0,\"dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[12],[1,\" \"],[1,[30,2,[\"email\"]]],[1,\" \"],[10,1],[14,0,\"p-l ion-ios-arrow-down\"],[12],[13],[13],[1,\"\\n                    \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[1,\"\\n                        \"],[10,\"li\"],[12],[1,\"\\n                            \"],[10,3],[15,6,[29,[\"https://intranet.aspensquare.com/employees/\",[30,2,[\"id\"]]]]],[12],[1,\"My Profile\"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                        \"],[10,\"li\"],[14,\"role\",\"separator\"],[14,0,\"divider\"],[12],[13],[1,\"\\n                        \"],[10,\"li\"],[12],[1,\"\\n                            \"],[10,3],[14,6,\"https://passwordreset.microsoftonline.com/\"],[12],[1,\"Reset Password\"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                        \"],[10,\"li\"],[12],[1,\"\\n                            \"],[10,3],[14,6,\"/auth/logout\"],[12],[10,\"i\"],[14,0,\"ion-power\"],[12],[13],[1,\" Logout\"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@isExternal\",\"@user\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"unless\"]]",
    "moduleName": "@aspen/aspen-navigation-controls/components/site-navigation/navigation-menu/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});