define('@aspen/aspen-search-controls/helpers/get-formatted-facet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getFormattedFacet = getFormattedFacet;
    function getFormattedFacet(params /*, hash*/) {
        let facetKey = params[0];
        let facets = params[1];
        let matches = facets.filter(f => f.key === facetKey);

        return matches.length === 1 ? matches[0].formatted : '';
    }

    exports.default = Ember.Helper.helper(getFormattedFacet);
});