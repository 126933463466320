define("@aspen/aspen-utils/helpers/format-date", ["exports", "@ember/component/helper", "moment"], function (_exports, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const formatDate = function (params /*, hash*/) {
    const date = params[0];
    const format = params[1] || 'LL';
    const utc = params[2];
    if (!date) {
      return '';
    }
    return utc ? (0, _moment.default)(date).utc().format(format) : (0, _moment.default)(date).format(format);
  };
  var _default = (0, _helper.helper)(formatDate);
  _exports.default = _default;
});