define("@aspen/aspen-utils/helpers/capitalize", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;
  function capitalize(value) {
    let val = value[0];
    return val ? val.capitalize() : val;
  }
  var _default = (0, _helper.helper)(capitalize);
  _exports.default = _default;
});