define("@aspen/aspen-utils/helpers/dasherize", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dasherize = dasherize;
  _exports.default = void 0;
  function dasherize(value) {
    let val = value[0];
    return (0, _string.dasherize)(val);
  }
  var _default = (0, _helper.helper)(dasherize);
  _exports.default = _default;
});