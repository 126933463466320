define("aspen-utils/user-authorization/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let findOne = function (haystack, arrayToLookFor) {
    return arrayToLookFor.some(v => {
      return haystack.map(i => i.toLowerCase()).indexOf(v.toLowerCase()) >= 0;
    });
  };
  var _default = _service.default.extend({
    setUser: function (user) {
      let service = this;
      service.user = user;
    },
    getUser: function () {
      let service = this;
      if (!service.user) {
        throw new Error('user has not been set');
      }
      return service.user;
    },
    isInRole: function (role) {
      let service = this;
      if (!service.user) {
        throw new Error('user has not been set');
      }
      if (!(role instanceof Array)) {
        role = [role];
      }
      return findOne(service.user.roles, role);
    }
  });
  _exports.default = _default;
});