define('@aspen/aspen-search-controls/components/search-filter/component', ['exports', '@aspen/aspen-search-controls/components/search-filter/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,

        tagName: 'span',

        actions: {
            clear: function () {
                let component = this;
                if (component.onClear) {
                    component.onClear();
                }
            }
        }
    });
});