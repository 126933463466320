define("aspen-utils/aspen-logger/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    fetchService: (0, _service.inject)('aspen-fetch'),
    init: function () {
      let service = this;
      let application = service.config.loggingApplicationIdentifier;
      this._super(...arguments);
      if (!application) {
        throw new Error('Must provide logging application identifier to use logging service');
      }
      service.set('loggingApplicationIdentifier', service.config.loggingApplicationIdentifier);
    },
    log: function (message, data, level) {
      let service = this;
      let fetchService = service.get('fetchService');
      let payload = {
        level: level,
        message: message,
        meta: {
          data: Object.assign(data || {}, {
            application: service.loggingApplicationIdentifier
          })
        }
      };
      let domain = service.config.environment === 'production' ? 'storage.api.aspensquare.com' : 'dev-storage-api.aspensquare.com';
      let logEndpoint = `https://${domain}/queues/log`;
      return fetchService.post(logEndpoint, {
        body: payload
      });
    },
    info: function (message, data) {
      return this.log(message, data, 'info');
    },
    warn: function (message, data) {
      return this.log(message, data, 'warn');
    },
    debug: function (message, data) {
      return this.log(message, data, 'debug');
    },
    error: function (message, data) {
      return this.log(message, data, 'error');
    },
    find: function (options) {
      let service = this;
      let fetchService = service.get('fetchService');
      let buildQuery = function (params) {
        let isNotNullOrUndefined = x => !(x === undefined || x === null || x === 'null' || x === 'undefined');
        return Object.keys(params).filter(k => isNotNullOrUndefined(params[k])).map(k => {
          if (!Array.isArray(params[k])) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
          } else {
            return params[k].map(v => encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
          }
        }).join('&');
      };
      let domain = service.config.environment === 'production' ? 'logging.api.aspensquare.com' : 'dev-logging-api.aspensquare.com';
      let logEndpoint = `https://${domain}/logs`;
      let uri = `${logEndpoint}/?${buildQuery(options)}`;
      return fetchService.fetch(uri);
    }
  });
  _exports.default = _default;
});