define("@aspen/aspen-utils/modifiers/navbar", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _emberModifier.modifier)(function navbar(element, _ref /*, positional, named*/) {
    let [initializer] = _ref;
    const $navbarNav = $("#navbarSupportedContent");
    if ($navbarNav) {
      const navbarNavCollapse = event => {
        if (!$navbarNav.is(event.target) && $navbarNav.has(event.target).length === 0) {
          $navbarNav.collapse('hide');
          document.removeEventListener("mouseup", navbarNavCollapse);
        }
      };
      $navbarNav.on("shown.bs.collapse", () => {
        document.addEventListener("mouseup", navbarNavCollapse);
      });
      const navLinks = document.getElementById('main-nav').getElementsByTagName('a');
      for (var i = 0; i < navLinks.length; i++) {
        navLinks[i].addEventListener("mouseup", () => {
          $navbarNav.collapse('hide');
          document.removeEventListener("mouseup", navbarNavCollapse);
        });
      }
    }
  });
  _exports.default = _default;
});