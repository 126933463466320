define("@aspen/aspen-utils/modifiers/initialize-plugin", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _emberModifier.modifier)(function initializePlugin(element, _ref) {
    let [initializer] = _ref;
    initializer(element);
  });
  _exports.default = _default;
});