define("@aspen/aspen-form-controls/components/toggle-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="form-group">
      <div class="form-check form-switch d-flex justify-content-between my-3 ps-0 pe-1">
          <label class="form-check-label"
                 for="form-check-{{@property}}">
              {{if @label @label}}
          </label>
          <Input
              {{on "click" this.toggleSwitch}}
                  class="form-check-input mt-1"
                  @type="checkbox"
                  id="form-check-{{@property}}"
                  disabled={{@isReadOnly}}
                  @checked={{changeset-get @changeset @property}}
          />
      </div>
  </div>
  
  */
  {
    "id": "VtKtLS9o",
    "block": "[[[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-check form-switch d-flex justify-content-between my-3 ps-0 pe-1\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"form-check-label\"],[15,\"for\",[29,[\"form-check-\",[30,1]]]],[12],[1,\"\\n            \"],[1,[52,[30,2],[30,2]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,1],[[24,0,\"form-check-input mt-1\"],[16,1,[29,[\"form-check-\",[30,1]]]],[16,\"disabled\",[30,3]],[4,[38,3],[\"click\",[30,0,[\"toggleSwitch\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[28,[37,2],[[30,4],[30,1]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@property\",\"@label\",\"@isReadOnly\",\"@changeset\"],false,[\"if\",\"input\",\"changeset-get\",\"on\"]]",
    "moduleName": "@aspen/aspen-form-controls/components/toggle-input.hbs",
    "isStrictMode": false
  });
  let ToggleInputComponent = (_class = class ToggleInputComponent extends _component2.default {
    constructor() {
      super(...arguments);
      const component = this;
      component.args.changeset.set(component.args.property, component.args.changeset.get(component.args.property) || false);
    }
    toggleSwitch(event) {
      this.args.changeset.set(this.args.property, event.target.checked);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "toggleSwitch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSwitch"), _class.prototype)), _class);
  _exports.default = ToggleInputComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ToggleInputComponent);
});