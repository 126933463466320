define("@aspen/aspen-utils/aspen-event-bus/service", ["exports", "@ember/object/evented", "@ember/service"], function (_exports, _evented, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend(_evented.default, {
    publish: function () {
      return this.trigger.apply(this, arguments);
    },
    subscribe: function () {
      return this.on.apply(this, arguments);
    },
    unsubscribe: function () {
      return this.off.apply(this, arguments);
    }
  });
  _exports.default = _default;
});