define("@aspen/aspen-user-session/user-session/service", ["exports", "@ember/service", "@ember/utils", "@glimmer/tracking", "fetch", "ember-cli-jwt-decode", "@aspen/aspen-user-session/aspen-local-storage/service"], function (_exports, _service, _utils, _tracking, _fetch, _emberCliJwtDecode, _service2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const aspenUserSessionKey = "aspenUserSession";
  let UserSessionService = (_class = class UserSessionService extends _service.default {
    // @service aspenLocalStorage;

    constructor() {
      super();
      _initializerDefineProperty(this, "authenticated", _descriptor, this);
      const userSession = this;
      const useWindowStrategy = userSession.config.tokenStrategy !== undefined && userSession.config.tokenStrategy.toLowerCase() === "window";
      const isDevelopment = userSession.config.environment === "development" || userSession.config.environment === "local";
      const useMockUser = isDevelopment && useWindowStrategy && window.aspen === undefined && userSession.config.mockUser;
      userSession.aspenLocalStorage = new _service2.default();

      /** For development **/
      if (useMockUser) {
        window.aspen = {
          user: userSession.config.mockUser
        };
      }
      const initialUser = useWindowStrategy ? {
        token: window.aspen.user.jwt
      } : userSession.getUser();
      if (!(0, _utils.isEmpty)(initialUser) && initialUser.token) {
        userSession.authenticate(initialUser.token, initialUser.provider);
      }
    }
    get isAuthenticated() {
      const service = this;
      const user = service.getUser();
      const doesUserExist = user !== undefined && user !== null;
      if (!doesUserExist) {
        return false;
      }
      const isAuthenticated = user.exp > Date.now() / 1000;
      service.authenticated = isAuthenticated;
      return isAuthenticated;
    }
    async login(username, password) {
      const userSession = this;
      const tokenUrl = userSession.config.identity.baseApiURL + "/token";
      const credentials = {
        username: username,
        password: password,
        grant_type: "password"
      };
      const urlEncode = function (data) {
        const str = [];
        for (const p in data) {
          if (data.hasOwnProperty(p) && data[p]) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
          }
        }
        return str.join("&");
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Accept": "application/json"
        },
        body: urlEncode(credentials)
      };
      const response = await (0, _fetch.default)(tokenUrl, options);
      const text = await response.text();
      if (!response.ok) {
        throw JSON.parse(text);
      }
      const auth = JSON.parse(text);
      userSession.authenticate(auth.access_token);
    }
    async logout() {
      const userSession = this;
      const logoutUrl = userSession.config.identity.baseApiURL + '/account/logout';
      await (0, _fetch.default)(logoutUrl, {
        method: "POST"
      });
      userSession.authenticated = false;
      userSession.aspenLocalStorage.removeItem(aspenUserSessionKey);
      return true;
    }
    authenticate(token, provider) {
      const userSession = this;
      const decodedToken = (0, _emberCliJwtDecode.jwt_decode)(token);
      const user = {
        token: token,
        email: decodedToken.unique_name,
        exp: decodedToken.exp,
        id: decodedToken.nameid || decodedToken.name,
        provider: provider || "Aspen",
        roles: decodedToken.role || []
      };
      userSession.authenticated = true;
      userSession.aspenLocalStorage.setItem(aspenUserSessionKey, user);
    }
    getUser() {
      const userSession = this;
      return userSession.aspenLocalStorage.getItem(aspenUserSessionKey);
    }
    get user() {
      return this.getUser();
    }
    isInRole(role) {
      const service = this;
      const findOne = (haystack, arrayToLookFor) => {
        return arrayToLookFor.some(v => {
          return haystack.map(i => i.toLowerCase()).indexOf(v.toLowerCase()) >= 0;
        });
      };
      const haystack = service.user.roles instanceof Array ? service.user.roles : [service.user.roles];
      if (!(role instanceof Array)) {
        role = [role];
      }
      return findOne(haystack, role);
    }
    getToken() {
      const userSession = this;
      const user = userSession.getUser();
      return user ? user.token : undefined;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = UserSessionService;
});