define("@aspen/aspen-form-controls/components/checkbox-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
      <div class='form-group mb-4 {{if (get @changeset.error @property) 'has-error'}} {{if @isReadOnly 'readonly'}}'>
          {{#if @label}}
              <label for={{concat 'checkbox-' @property}}
                     class='form-label {{if @required 'required' ''}}'>{{@label}}</label>
          {{/if}}
  
          <input {{on "click" this.updateValue}}
                  readonly={{@isReadOnly}}
                  class='form-control {{if (get @changeset.error @property) 'bg-danger' ''}}'
                  type='checkbox' id={{concat 'checkbox-' @property}}
                  checked={{this.value}}>
      </div>
  </div>
  
  */
  {
    "id": "7cwXcqX8",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"form-group mb-4 \",[52,[28,[37,1],[[30,1,[\"error\"]],[30,2]],null],\"has-error\"],\" \",[52,[30,3],\"readonly\"]]]],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"            \"],[10,\"label\"],[15,\"for\",[28,[37,2],[\"checkbox-\",[30,2]],null]],[15,0,[29,[\"form-label \",[52,[30,5],\"required\",\"\"]]]],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[11,\"input\"],[16,\"readonly\",[30,3]],[16,0,[29,[\"form-control \",[52,[28,[37,1],[[30,1,[\"error\"]],[30,2]],null],\"bg-danger\",\"\"]]]],[16,1,[28,[37,2],[\"checkbox-\",[30,2]],null]],[16,\"checked\",[30,0,[\"value\"]]],[24,4,\"checkbox\"],[4,[38,3],[\"click\",[30,0,[\"updateValue\"]]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@changeset\",\"@property\",\"@isReadOnly\",\"@label\",\"@required\"],false,[\"if\",\"get\",\"concat\",\"on\"]]",
    "moduleName": "@aspen/aspen-form-controls/components/checkbox-input.hbs",
    "isStrictMode": false
  });
  let CheckboxInputComponent = (_class = class CheckboxInputComponent extends _component2.default {
    get value() {
      return this.args.changeset.get(this.args.property);
    }
    updateValue(event) {
      this.args.changeset.set(this.args.property, event.target.checked);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class);
  _exports.default = CheckboxInputComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CheckboxInputComponent);
});