define("@aspen/aspen-utils/utils/validations", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDate = validateDate;
  /*
   * options:
   *  {
   *    beforeKey: { key, message }, // changest key has date occurring before this value
   *    afterKey: { key, message }, // changest key has date occurring after this value
   *    beforeDate: { date, message }, // date occurs before this date
   *    afterDate: { date, message } // date occurs after this date
   *    dateValidator: `moment => true` if valid else `moment => error message`
   *  }
   */
  function validateDate(_ref) {
    let {
      beforeKey,
      afterKey,
      beforeDate,
      afterDate,
      allowBlank,
      dateValidator
    } = _ref;
    const [beforeMoment, afterMoment] = [{
      name: 'beforeDate',
      date: beforeDate && beforeDate.date
    }, {
      name: 'afterDate',
      date: afterDate && afterDate.date
    }].map(_ref2 => {
      let {
        name,
        date
      } = _ref2;
      if ((0, _utils.isBlank)(date)) {
        return;
      }
      const m = moment(date).utc();
      if (m.isValid()) {
        return m;
      }
      throw new Error(`'${name}' has invalid date '${date}`);
    });
    return (key, newValue, oldValue, changes, content) => {
      if ((0, _utils.isBlank)(newValue)) {
        return allowBlank ? true : 'Date required';
      }
      const m = moment(newValue);
      if (!m.isValid()) {
        return 'Invalid date';
      }
      if (dateValidator) {
        const isValid = dateValidator(m);
        if (isValid !== true) {
          return isValid;
        }
      }
      if (beforeMoment && m.isBefore(beforeMoment)) {
        return beforeDate.message || `Date cannot be before ${beforeDate}`;
      }
      if (afterMoment && m.isAfter(afterMoment)) {
        return afterDate.message || `Date cannot be after ${afterDate}`;
      }
      const [beforeValue, afterValue] = [beforeKey && beforeKey.key, afterKey && afterKey.key].map(k => {
        if ((0, _utils.isBlank)(k)) {
          return;
        }
        const v = moment(changes.hasOwnProperty(k) ? changes[k] : (0, _object.get)(content, k));
        return v.isValid() ? v : undefined;
      });
      const notBefore = beforeValue && m.isBefore(beforeValue);
      const notAfter = afterValue && m.isAfter(afterValue);
      if (notBefore && notAfter) {
        return `${key} must come before ${afterKey.key} and after ${beforeKey.key}`;
      }
      if (notBefore) {
        return beforeKey.message || `${key} must come after ${beforeKey.key}`;
      }
      if (notAfter) {
        return `${key} must come before ${afterKey.key}`;
      }
      return true;
    };
  }
});