define("aspen-utils/helpers/to-url", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toUrl = toUrl;
  function toUrl(params /*, hash*/) {
    return params;
  }
  var _default = (0, _helper.helper)(toUrl);
  _exports.default = _default;
});