define("@aspen/aspen-utils/aspen-fetch/service", ["exports", "@ember/service", "@glimmer/tracking", "fetch"], function (_exports, _service, _tracking, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const contentTypeKey = 'Content-Type'.toLowerCase();
  const applicationJson = 'application/json'.toLowerCase();
  const accept = 'Accept'.toLowerCase();
  let AspenFetchService = (_class = class AspenFetchService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "networkRequests", _descriptor, this);
      _initializerDefineProperty(this, "userSession", _descriptor2, this);
    }
    async fetch(input, init) {
      const service = this;
      const token = service.userSession.getToken();
      const responseStrategies = {
        default: function (response) {
          return response.text().then(function (text) {
            if (!response.ok) {
              return Promise.reject({
                error: text,
                response: response
              });
            }
            return {
              data: text ? JSON.parse(text) : {},
              response: response
            };
          });
        },
        blob: function (response) {
          if (response.ok) {
            return response.blob().then(function (blob) {
              return {
                data: blob,
                response: response
              };
            });
          }
          return response.text().then(function (text) {
            return Promise.reject({
              error: text ? JSON.parse(text) : {},
              response: response
            });
          });
        }
      };
      const parseResponse = function (response) {
        const responseStrategy = responseStrategies[init.responseType];
        service.networkRequests--;
        if (responseStrategy) {
          return responseStrategy(response);
        }
      };
      init = init || {
        method: 'GET'
      };
      init.responseType = init.responseType || 'default';
      init.headers = init.headers || {};
      init.headers[contentTypeKey] = init.headers[contentTypeKey] || applicationJson;
      init.headers[accept] = init.headers[accept] || applicationJson;
      if (!init.suppressAuthorizationHeader && token) {
        init.headers['Authorization'] = 'Bearer ' + token;
      }
      if (init.body && init.headers[contentTypeKey] === applicationJson) {
        init.body = JSON.stringify(init.body);
      }
      service.networkRequests++;
      return parseResponse(await (0, _fetch.default)(input, init));
    }
    post(url, init) {
      const service = this;
      init.method = 'POST';
      return service.fetch(url, init);
    }
    put(url, init) {
      const service = this;
      init.method = 'PUT';
      return service.fetch(url, init);
    }
    patch(url, init) {
      const service = this;
      init.method = 'PATCH';
      return service.fetch(url, init);
    }
    delete(url) {
      const service = this;
      return service.fetch(url, {
        method: 'DELETE'
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "networkRequests", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AspenFetchService;
});