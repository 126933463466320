define("@aspen/aspen-navigation-controls/components/site-navigation/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KjP7VNbb",
    "block": "[[[10,\"nav\"],[14,0,\"navbar navbar-fixed-top navbar-padded site-navigation\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,0],[[24,0,\"navbar-brand text-uppercase\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n                \"],[41,[30,1],[[[10,1],[12],[1,[30,1]],[13]],[]],[[[10,1],[12],[1,\"Aspen \"],[10,\"strong\"],[12],[1,\"Square\"],[13],[13]],[]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[18,5,[[28,[37,3],null,[[\"breadcrumb\",\"menu\",\"mobile-menu\"],[[50,\"site-navigation/navigation-breadcrumb\",0,null,[[\"applicationName\"],[[30,2]]]],[50,\"site-navigation/navigation-menu\",0,null,[[\"user\",\"isExternal\"],[[30,3],[30,4]]]],[50,\"site-navigation/navigation-mobile-menu\",0,null,[[\"user\",\"applicationName\",\"isExternal\"],[[30,3],[30,2],[30,4]]]]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"@applicationName\",\"@user\",\"@isExternal\",\"&default\"],false,[\"link-to\",\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@aspen/aspen-navigation-controls/components/site-navigation/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});