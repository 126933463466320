define('vendor-utils', ['exports'], function (exports) { 'use strict';

  var propertyBids = function getPropertyBids({ documentStatusTypes, documentTypes, vendor }) {
    const documentStatusTypeByCode = (code) =>
      documentStatusTypes.find((t) => t.code === code).id;
    const documentTypeByCode = (code) =>
      documentTypes.find((t) => t.code === code).id;
    const bidDocumentTypeId = documentTypeByCode('BID');
    const newDocumentStatusTypeId = documentStatusTypeByCode('NEW');
    const pendingDocumentStatusTypeId = documentStatusTypeByCode('PENDING');
    const byActiveNewOrPendingBid = (d) => d.active
      && d.documentTypeId === bidDocumentTypeId
      && (d.documentStatusTypeId === newDocumentStatusTypeId
        || d.documentStatusTypeId === pendingDocumentStatusTypeId);
    const toProperty = (d) => d.propertyDescription;

    return [...(new Set(
      (vendor.documents || [])
        .filter(byActiveNewOrPendingBid)
        .map(toProperty)
    )).keys()];
  };

  var vendorInsuranceCompliance = class VendorInsuranceCompliance {
    constructor({ bids, documentStatusTypes, insuranceCoverageTypes, serviceTypes, vendor }) {
      this.bids = bids;
      this.documentStatusTypes = documentStatusTypes;
      this.insuranceCoverageTypes = insuranceCoverageTypes;
      this.serviceTypes = serviceTypes;
      this.vendor = vendor;

      this.conditionalAutoServiceTypes = new Set([
        'Aquatic Management',
        'Chimney Sweep',
        'Contractor-Exterior',
        'Contractor-Interior',
        'Gate & Fence Installation / Repair',
        'HVAC',
        'Multi-Service - Exterior',
        'Painting - Exterior',
        'Pest Control',
        'Plumbing',
        'Professional Services',
        'Inspections/Permitting',
        'Gutter Installation / Repair',
        'Backflow Service',
        'Carpet Cleaning',
        'Duct & Vent Cleaning',
        'Equip & Appliance Repair',
        'Equipment Lease / Rental',
        'Flooring Installation',
        'Glass Repair & Installation',
        'Interior Cleaning',
        'Locksmith',
        'Multi-Service - Interior',
        'Painting - Interior',
        'Pool Repair / Service',
        'Resurfacing / Refinishing',
        'Temp Labor Service',
        'Insulation',
        'Alarm/Fire System',
        'Electrician',
        'Elevator Service',
        'Environmental Abatement',
        'Environmental Testing',
        'Roofing',
        'Security',
        'Welding & Fabrication',
        'Police Officer / Sheriff Deputy',
        'Rubbish'
      ]);
    }

    isEndorsementRequiredFor(endorsementFieldName, coverageTypeId) {
      const { documentStatusTypes, serviceTypes, bids } = this;
      const deniedDocumentStatusTypeId = documentStatusTypes
        .find((t) => t.code === 'DENIED').id;
      const byActiveAndNotDenied = ({
        active,
        documentStatusTypeId
      }) =>
        active && documentStatusTypeId !== deniedDocumentStatusTypeId;
      const bidServices = bids.filter(byActiveAndNotDenied)
        .map((b) => b.serviceTypeId);

      const summary = serviceTypes
        .filter((s) => bidServices.includes(s.id))
        .map((s) =>
          s.insuranceRequirements.filter(
            (r) => r.coverageTypeId === coverageTypeId
          ).map((r) => {
            r.responsibleServiceTypes = [
              { id: s.id, description: s.description }
            ];
            return r;
          })
        )
        .flat()
        .filter((r) => r[endorsementFieldName])
        .reduce((summary, r) => {
          summary.responsibleServiceTypes = summary.responsibleServiceTypes
            .concat(r.responsibleServiceTypes);
          return summary;
        }, { coverageTypeId, endorsementFieldName, responsibleServiceTypes:[] });
      return summary.responsibleServiceTypes.length ? summary : false;
    }

    coverageTypeRequirementMet({ byRequirement, coverageTypeId, requirement }) {
      const {
        vendor: { insurance: { coverages = [] } = {} }
      } = this;
      const byCoverageTypeAndExpirationDate = ({ effectiveDate, expirationDate, insuranceCoverageTypeId }) =>
        insuranceCoverageTypeId === coverageTypeId
          && new Date(effectiveDate) <= new Date()
          && new Date() <= new Date(expirationDate);

      return (
        !requirement || coverages.filter(byCoverageTypeAndExpirationDate).some(byRequirement)
      );
    }

    get insuranceRequirements() {
      const {
        autoLiabilityCoverageTypeId,
        bids,
        conditionalAutoServiceTypes,
        documentStatusTypes,
        serviceTypes,
        vendor: {
          packet
        },
        workersCompCoverageTypeId
      } = this;


      const doesVendorHaveCompanyVehicles = packet ? packet.doesVendorHaveCompanyVehicles : true;
      const doesVendorHaveEmployees = packet ? packet.doesVendorHaveEmployees : true;
      const deniedDocumentStatusTypeId = documentStatusTypes
        .find((t) => t.code === 'DENIED').id;
      const byActiveAndNotDenied = ({
        active,
        documentStatusTypeId
      }) =>
        active && documentStatusTypeId !== deniedDocumentStatusTypeId;

      return bids
        .filter(byActiveAndNotDenied)
        .reduce((accumulatedRequirements, bid) => {
          const { description, insuranceRequirements } =
          serviceTypes.find(
            (s) =>
              s.id === bid.serviceTypeId ||
              s.description === bid.serviceType
          );
          const workersCompForNoEmployees = (r) => {
            if(r.coverageTypeId === workersCompCoverageTypeId) {
              return doesVendorHaveEmployees;
            }
            return true;
          };
          const autoForNoCompanyVehicles = (r) => {
            if(
              r.coverageTypeId === autoLiabilityCoverageTypeId &&
              conditionalAutoServiceTypes.has(description)
            ) {
              return doesVendorHaveCompanyVehicles;
            }
            return true;
          };
          insuranceRequirements
            .filter(workersCompForNoEmployees)
            .filter(autoForNoCompanyVehicles)
            .forEach(({ coverageTypeId, limit }) => {
              const requirementByCoverageType =
                accumulatedRequirements.find(
                  (a) => a.coverageTypeId === coverageTypeId
                );

              if(!requirementByCoverageType) {
                accumulatedRequirements.push({
                  coverageTypeId,
                  limit,
                  responsibleServiceTypes: [
                    { id: bid.serviceTypeId, description }
                  ]
                });
              } else if(requirementByCoverageType.limit < limit) {
                requirementByCoverageType.limit = limit;
                requirementByCoverageType.responsibleServiceTypes = [{ id: bid.serviceTypeId, description }];
              } else if(requirementByCoverageType.limit === limit) {
                requirementByCoverageType.responsibleServiceTypes.push({ id: bid.serviceTypeId, description });
              }
            });

          return accumulatedRequirements;
        }, []);
    }

    get generalLiabilityCoverageTypeId() {
      return this.insuranceCoverageTypes.find((t) => t.code === 'GL').id;
    }

    get generalLiabilityRequirement() {
      const { insuranceRequirements, generalLiabilityCoverageTypeId } = this;
      return insuranceRequirements.find(
        (r) => r.coverageTypeId === generalLiabilityCoverageTypeId
      );
    }

    get generalLiabilityAdditionalInsuredOngoingOperations() {
      return this.isEndorsementRequiredFor(
        'additionalInsuredOngoingOperations',
        this.generalLiabilityCoverageTypeId
      );
    }

    get generalLiabilityAdditionalInsuredYourWork() {
      return this.isEndorsementRequiredFor(
        'additionalInsuredYourWork',
        this.generalLiabilityCoverageTypeId
      );
    }

    get generalLiabilityPrimaryAndNonContributory() {
      return this.isEndorsementRequiredFor(
        'primaryNonContributory',
        this.generalLiabilityCoverageTypeId
      );
    }

    get generalLiabilityWaiverOfSubrogation() {
      return this.isEndorsementRequiredFor(
        'waiverOfSubrogation',
        this.generalLiabilityCoverageTypeId
      );
    }

    get generalLiabilityNoticeOfCancellation() {
      return this.isEndorsementRequiredFor(
        'noticeOfCancellation',
        this.generalLiabilityCoverageTypeId
      );
    }

    get autoLiabilityCoverageTypeId() {
      return this.insuranceCoverageTypes.find((t) => t.code === 'A').id;
    }

    get autoLiabilityRequirement() {
      const { autoLiabilityCoverageTypeId, insuranceRequirements } = this;
      return insuranceRequirements.find(
        (r) => r.coverageTypeId === autoLiabilityCoverageTypeId
      );
    }

    get autoLiabilityAdditionalInsuredOngoingOperations() {
      return this.isEndorsementRequiredFor(
        'additionalInsuredOngoingOperations',
        this.autoLiabilityCoverageTypeId
      );
    }

    get autoLiabilityPrimaryAndNonContributory() {
      return this.isEndorsementRequiredFor(
        'primaryNonContributory',
        this.autoLiabilityCoverageTypeId
      );
    }

    get autoLiabilityWaiverOfSubrogation() {
      return this.isEndorsementRequiredFor(
        'waiverOfSubrogation',
        this.autoLiabilityCoverageTypeId
      );
    }

    get autoLiabilityNoticeOfCancellation() {
      return this.isEndorsementRequiredFor(
        'noticeOfCancellation',
        this.autoLiabilityCoverageTypeId
      );
    }

    get workersCompCoverageTypeId() {
      return this.insuranceCoverageTypes.find((t) => t.code === 'WC').id;
    }

    get workersCompRequirement() {
      const { insuranceRequirements, workersCompCoverageTypeId } = this;
      return insuranceRequirements.find(
        (r) => r.coverageTypeId === workersCompCoverageTypeId
      );
    }

    get workersCompWaiverOfSubrogation() {
      return this.isEndorsementRequiredFor(
        'waiverOfSubrogation',
        this.workersCompCoverageTypeId
      );
    }

    get workersCompNoticeOfCancellation() {
      return this.isEndorsementRequiredFor(
        'noticeOfCancellation',
        this.workersCompCoverageTypeId
      );
    }

    get pollutionCoverageTypeId() {
      return this.insuranceCoverageTypes.find((t) => t.code === 'P').id;
    }

    get pollutionRequirement() {
      const { insuranceRequirements, pollutionCoverageTypeId } = this;
      return insuranceRequirements.find(
        (r) => r.coverageTypeId === pollutionCoverageTypeId
      );
    }

    get errorsAndOmissionsCoverageTypeId() {
      return this.insuranceCoverageTypes.find((t) => t.code === 'PEO').id;
    }

    get errorsAndOmissionsRequirement() {
      const { errorsAndOmissionsCoverageTypeId, insuranceRequirements } =
      this;
      return insuranceRequirements.find(
        (r) => r.coverageTypeId === errorsAndOmissionsCoverageTypeId
      );
    }

    get crimeCoverageTypeId() {
      return this.insuranceCoverageTypes.find((t) => t.code === 'CAB').id;
    }

    get crimeRequirement() {
      const { crimeCoverageTypeId, insuranceRequirements } = this;
      return insuranceRequirements.find(
        (r) => r.coverageTypeId === crimeCoverageTypeId
      );
    }

    get snowRemovalCoverageTypeId() {
      return this.insuranceCoverageTypes.find((t) => t.code === 'SN').id;
    }

    get snowRemovalRequirement() {
      const { insuranceRequirements, snowRemovalCoverageTypeId } = this;
      return insuranceRequirements.find(
        (r) => r.coverageTypeId === snowRemovalCoverageTypeId
      );
    }

    get generalLiabilityRequirementMet() {
      const {
        generalLiabilityCoverageTypeId: coverageTypeId,
        generalLiabilityRequirement: requirement,
        generalLiabilityRequirement: { limit: requiredLimit = 0 } = {}
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ limit }) => limit >= requiredLimit,
        coverageTypeId,
        requirement
      });
    }

    get generalLiabilityAdditionalInsuredOngoingOperationsMet() {
      const {
        generalLiabilityAdditionalInsuredOngoingOperations: requirement,
        generalLiabilityCoverageTypeId: coverageTypeId
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ additionalInsuredOngoingOperations }) =>
          additionalInsuredOngoingOperations,
        coverageTypeId,
        requirement
      });
    }

    get generalLiabilityAdditionalInsuredYourWorkMet() {
      const {
        generalLiabilityAdditionalInsuredYourWork: requirement,
        generalLiabilityCoverageTypeId: coverageTypeId
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ additionalInsuredYourWork }) =>
          additionalInsuredYourWork,
        coverageTypeId,
        requirement
      });
    }

    get generalLiabilityPrimaryAndNonContributoryMet() {
      const {
        generalLiabilityCoverageTypeId: coverageTypeId,
        generalLiabilityPrimaryAndNonContributory: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ primaryNonContributory }) =>
          primaryNonContributory,
        coverageTypeId,
        requirement
      });
    }

    get generalLiabilityWaiverOfSubrogationMet() {
      const {
        generalLiabilityCoverageTypeId: coverageTypeId,
        generalLiabilityWaiverOfSubrogation: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ waiverOfSubrogation }) => waiverOfSubrogation,
        coverageTypeId,
        requirement
      });
    }

    get generalLiabilityNoticeOfCancellationMet() {
      const {
        generalLiabilityCoverageTypeId: coverageTypeId,
        generalLiabilityNoticeOfCancellation: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ noticeOfCancellation }) => noticeOfCancellation,
        coverageTypeId,
        requirement
      });
    }

    get autoLiabilityRequirementMet() {
      const {
        autoLiabilityCoverageTypeId: coverageTypeId,
        autoLiabilityRequirement: requirement,
        autoLiabilityRequirement: { limit: requiredLimit = 0 } = {}
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ limit }) => limit >= requiredLimit,
        coverageTypeId,
        requirement
      });
    }

    get autoLiabilityAdditionalInsuredOngoingOperationsMet() {
      const {
        autoLiabilityAdditionalInsuredOngoingOperations: requirement,
        autoLiabilityCoverageTypeId: coverageTypeId
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ additionalInsuredOngoingOperations }) =>
          additionalInsuredOngoingOperations,
        coverageTypeId,
        requirement
      });
    }

    get autoLiabilityPrimaryAndNonContributoryMet() {
      const {
        autoLiabilityCoverageTypeId: coverageTypeId,
        autoLiabilityPrimaryAndNonContributory: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ primaryNonContributory }) =>
          primaryNonContributory,
        coverageTypeId,
        requirement
      });
    }

    get autoLiabilityWaiverOfSubrogationMet() {
      const {
        autoLiabilityCoverageTypeId: coverageTypeId,
        autoLiabilityWaiverOfSubrogation: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ waiverOfSubrogation }) => waiverOfSubrogation,
        coverageTypeId,
        requirement
      });
    }

    get autoLiabilityNoticeOfCancellationMet() {
      const {
        autoLiabilityCoverageTypeId: coverageTypeId,
        autoLiabilityNoticeOfCancellation: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ noticeOfCancellation }) => noticeOfCancellation,
        coverageTypeId,
        requirement
      });
    }

    get workersCompRequirementMet() {
      const {
        workersCompCoverageTypeId: coverageTypeId,
        workersCompRequirement: requirement,
        workersCompRequirement: { limit: requiredLimit = 0 } = {}
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ limit }) => limit >= requiredLimit,
        coverageTypeId,
        requirement
      });
    }

    get workersCompWaiverOfSubrogationMet() {
      const {
        workersCompCoverageTypeId: coverageTypeId,
        workersCompWaiverOfSubrogation: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ waiverOfSubrogation }) => waiverOfSubrogation,
        coverageTypeId,
        requirement
      });
    }

    get workersCompNoticeOfCancellationMet() {
      const {
        workersCompCoverageTypeId: coverageTypeId,
        workersCompNoticeOfCancellation: requirement
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ noticeOfCancellation }) => noticeOfCancellation,
        coverageTypeId,
        requirement
      });
    }

    get pollutionRequirementMet() {
      const {
        pollutionCoverageTypeId: coverageTypeId,
        pollutionRequirement: requirement,
        pollutionRequirement: { limit: requiredLimit = 0 } = {}
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ limit }) => limit >= requiredLimit,
        coverageTypeId,
        requirement
      });
    }

    get errorsAndOmissionsRequirementMet() {
      const {
        errorsAndOmissionsCoverageTypeId: coverageTypeId,
        errorsAndOmissionsRequirement: requirement,
        errorsAndOmissionsRequirement: { limit: requiredLimit = 0 } = {}
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ limit }) => limit >= requiredLimit,
        coverageTypeId,
        requirement
      });
    }

    get crimeRequirementMet() {
      const {
        crimeCoverageTypeId: coverageTypeId,
        crimeRequirement: requirement,
        crimeRequirement: { limit: requiredLimit = 0 } = {}
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ limit }) => limit >= requiredLimit,
        coverageTypeId,
        requirement
      });
    }

    get snowRemovalRequirementMet() {
      const {
        snowRemovalCoverageTypeId: coverageTypeId,
        snowRemovalRequirement: requirement,
        snowRemovalRequirement: { limit: requiredLimit = 0 } = {}
      } = this;

      return this.coverageTypeRequirementMet({
        byRequirement: ({ limit }) => limit >= requiredLimit,
        coverageTypeId,
        requirement
      });
    }

    get allRequirementsMet() {
      return (
        this.generalLiabilityRequirementMet &&
        this.generalLiabilityAdditionalInsuredOngoingOperationsMet &&
        this.generalLiabilityAdditionalInsuredYourWorkMet &&
        this.generalLiabilityPrimaryAndNonContributoryMet &&
        this.generalLiabilityWaiverOfSubrogationMet &&
        this.generalLiabilityNoticeOfCancellationMet &&
        this.autoLiabilityRequirementMet &&
        this.autoLiabilityAdditionalInsuredOngoingOperationsMet &&
        this.autoLiabilityPrimaryAndNonContributoryMet &&
        this.autoLiabilityWaiverOfSubrogationMet &&
        this.autoLiabilityNoticeOfCancellationMet &&
        this.workersCompRequirementMet &&
        this.workersCompWaiverOfSubrogationMet &&
        this.workersCompNoticeOfCancellationMet &&
        this.pollutionRequirementMet &&
        this.errorsAndOmissionsRequirementMet &&
        this.crimeRequirementMet &&
        this.snowRemovalRequirementMet
      );
    }

    toJSON() {
      // https://stackoverflow.com/a/50785428/1582066
      const result = Object.assign({}, this);
      const proto = Object.getPrototypeOf(this);
      for (const key of Object.getOwnPropertyNames(proto)) {
        const desc = Object.getOwnPropertyDescriptor(proto, key);
        const hasGetter = desc && typeof desc.get === 'function';
        if (hasGetter) {
          result[key] = this[key];
        }
      }
      return result;
    }
  };

  var vendorTags = (() => {
    const tagTests = [
      function missingW9({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[], onboarding } }){
        const w9DocumentTypeId = documentTypeByCode('W9');
        const acceptedDocumentStatusTypeId = documentStatusTypeByCode('ACCEPTED');
        const hasAcceptedW9 = (d) => d.documentTypeId === w9DocumentTypeId
          && d.documentStatusTypeId === acceptedDocumentStatusTypeId;

        return (onboarding || onboarding === null) && !documents.some(hasAcceptedW9) && 'missingW9';
      },
      function missingBid({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[], onboarding } }){
        const bidDocumentTypeId = documentTypeByCode('BID');
        const deniedDocumentStatusTypeId = documentStatusTypeByCode('DENIED');
        const hasNonDeniedBid = (d) => d.documentTypeId === bidDocumentTypeId
          && d.documentStatusTypeId !== deniedDocumentStatusTypeId;

        return (onboarding || onboarding === null) && !documents.some(hasNonDeniedBid) && 'missingBid';
      },
      function missingLicense({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[], onboarding, packet } }){
        const doesVendorLineOfWorkRequireLicense = packet ? packet.doesVendorLineOfWorkRequireLicense : false;
        const licenseDocumentTypeId = documentTypeByCode('LIC');
        const acceptedDocumentStatusTypeId = documentStatusTypeByCode('ACCEPTED');
        const hasAcceptedLicense = (d) => d.documentTypeId === licenseDocumentTypeId
          && d.documentStatusTypeId === acceptedDocumentStatusTypeId;

        return (onboarding || onboarding === null) && doesVendorLineOfWorkRequireLicense && !documents.some(hasAcceptedLicense) && 'missingLicense';
      },
      function readyForMsa({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[], onboarding }, vendorInsuranceCompliance }){
        const bidDocumentTypeId = documentTypeByCode('BID');
        const msaDocumentTypeId = documentTypeByCode('MSA');
        const deniedDocumentStatusTypeId = documentStatusTypeByCode('DENIED');
        const newDocumentStatusTypeId = documentStatusTypeByCode('NEW');
        const pendingDocumentStatusTypeId = documentStatusTypeByCode('PENDING');
        const hasNonDeniedMsa = (d) => d.documentTypeId === msaDocumentTypeId
          && d.documentStatusTypeId !== deniedDocumentStatusTypeId;
        const hasNewOrPendingBid = (d) => d.documentTypeId === bidDocumentTypeId
          && (d.documentStatusTypeId === newDocumentStatusTypeId
            || d.documentStatusTypeId === pendingDocumentStatusTypeId);

        return (onboarding || onboarding === null)
          && !documents.some(hasNonDeniedMsa)
          && documents.some(hasNewOrPendingBid)
          && vendorInsuranceCompliance.allRequirementsMet
          && 'readyForMsa';
      },
      function missingMsaSignature({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[] } }){
        const msaDocumentTypeId = documentTypeByCode('MSA');
        const pendingDocumentStatusTypeId = documentStatusTypeByCode('PENDING');
        const hasPendingMsa = (d) => d.documentTypeId === msaDocumentTypeId
          && d.documentStatusTypeId === pendingDocumentStatusTypeId;

        return documents.some(hasPendingMsa) && 'missingMsaSignature';
      },
      function missingInsuranceCoverage({ vendorInsuranceCompliance }){
        return !vendorInsuranceCompliance.allRequirementsMet && 'missingInsuranceCoverage';
      },
      function bidPendingOpsApproval({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[] } }){
        const bidDocumentTypeId = documentTypeByCode('BID');
        const newDocumentStatusTypeId = documentStatusTypeByCode('NEW');
        const hasNewBid = (d) => d.documentTypeId === bidDocumentTypeId
          && d.documentStatusTypeId === newDocumentStatusTypeId;

        return documents.some(hasNewBid)
          && 'bidPendingOpsApproval';
      },
      function approvedBid({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[] } }){
        const bidDocumentTypeId = documentTypeByCode('BID');
        const pendingDocumentStatusTypeId = documentStatusTypeByCode('PENDING');
        const hasPendingBid = (d) => d.documentTypeId === bidDocumentTypeId
          && d.documentStatusTypeId === pendingDocumentStatusTypeId;

        return documents.some(hasPendingBid)
          && 'approvedBid';
      },
      function readyToAcceptBid({ documentStatusTypeByCode, documentStatusTypes, documentTypeByCode, insuranceCoverageTypes, serviceTypes, vendor }){
        const { documents=[] } = vendor;
        const bidDocumentTypeId = documentTypeByCode('BID');
        const msaDocumentTypeId = documentTypeByCode('MSA');
        const acceptedDocumentStatusTypeId = documentStatusTypeByCode('ACCEPTED');
        const pendingDocumentStatusTypeId = documentStatusTypeByCode('PENDING');
        const hasAcceptedMsa = (d) => d.documentTypeId === msaDocumentTypeId
          && d.documentStatusTypeId === acceptedDocumentStatusTypeId;
        const hasPendingInsuranceCompliantBid = (d) => d.documentTypeId === bidDocumentTypeId
          &&  d.documentStatusTypeId === pendingDocumentStatusTypeId
          && new vendorInsuranceCompliance({
            bids: [d],
            documentStatusTypes,
            insuranceCoverageTypes,
            serviceTypes,
            vendor
          }).allRequirementsMet;

        return documents.some(hasAcceptedMsa)
          && documents.some(hasPendingInsuranceCompliantBid)
          && 'readyToAcceptBid';
      },
      function insuranceCertificateMissingCoverage({ documentStatusTypeByCode, documentTypeByCode, vendor: { documents=[], insurance: { coverages=[] }={} }, vendorInsuranceCompliance }){
        const coiDocumentTypeId = documentTypeByCode('COI');
        const deniedDocumentStatusTypeId = documentStatusTypeByCode('DENIED');
        const hasUnreferencedCoi = (d) => d.documentTypeId === coiDocumentTypeId
          && d.documentStatusTypeId !== deniedDocumentStatusTypeId
          && !coverages.some((c) => c.documentId === d.id);

        return documents.some(hasUnreferencedCoi)
          && !vendorInsuranceCompliance.allRequirementsMet
          && 'insuranceCertificateMissingCoverage';
      }
    ];

    return function getVendorTags({ documentStatusTypes, documentTypes, insuranceCoverageTypes, serviceTypes, vendor }) {
      const documentStatusTypeByCode = (code) =>
        documentStatusTypes.find((t) => t.code === code).id;
      const documentTypeByCode = (code) =>
        documentTypes.find((t) => t.code === code).id;
      vendor.documents = (vendor.documents || []).filter((d) => d.active);
      const vendorInsuranceCompliance$1 = new vendorInsuranceCompliance({
        bids: (vendor.documents || [])
          .filter((d) => d.documentTypeId === documentTypeByCode('BID')
            && d.documentStatusTypeId !== documentStatusTypeByCode('DENIED')
          ),
        documentStatusTypes,
        insuranceCoverageTypes,
        serviceTypes,
        vendor
      });

      return tagTests
        .map((t) => t({ documentStatusTypeByCode, documentStatusTypes, documentTypeByCode, insuranceCoverageTypes, serviceTypes, vendor, vendorInsuranceCompliance: vendorInsuranceCompliance$1 }))
        .filter((s) => s);
    };
  })();

  var lib = {
    getPropertyBids: propertyBids,
    getVendorTags: vendorTags,
    VendorInsuranceCompliance: vendorInsuranceCompliance
  };
  var lib_1 = lib.getPropertyBids;
  var lib_2 = lib.getVendorTags;
  var lib_3 = lib.VendorInsuranceCompliance;

  exports.VendorInsuranceCompliance = lib_3;
  exports.default = lib;
  exports.getPropertyBids = lib_1;
  exports.getVendorTags = lib_2;

  Object.defineProperty(exports, '__esModule', { value: true });

});
