define("@aspen/aspen-utils/query-builder/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    buildQuery: function (params) {
      let isNotNullOrUndefined = x => !(x === undefined || x === null || x === 'null' || x === 'undefined');
      let isNotEmptyArray = x => !(Array.isArray(x) && !x.length);
      return Object.keys(params).filter(k => isNotNullOrUndefined(params[k])).filter(k => isNotEmptyArray(params[k])).map(k => {
        if (!Array.isArray(params[k])) {
          return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
        } else {
          return params[k].map(v => encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
        }
      }).join('&');
    },
    urlEncode: function (data) {
      let str = [];
      for (let p in data) {
        if (data.hasOwnProperty(p) && data[p]) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
        }
      }
      return str.join('&');
    }
  });
  _exports.default = _default;
});