define("@aspen/aspen-navigation-controls/components/site-navigation/navigation-mobile-menu/component", ["exports", "@ember/service", "@ember/component", "@aspen/aspen-navigation-controls/components/site-navigation/navigation-mobile-menu/template"], function (_exports, _service, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _template.default,
    menu: (0, _service.inject)('side-menu'),
    actions: {
      toggleMenu() {
        this.get('menu').toggle();
      }
    }
  });
  _exports.default = _default;
});