define("aspen-utils/document/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    open: function (data, fileName, format) {
      var blob = new Blob([data], {
        type: format
      });
      var link = document.createElement('a');
      if (link.download !== undefined) {
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      } else {
        navigator.msSaveOrOpenBlob(blob, fileName);
      }
    }
  });
  _exports.default = _default;
});