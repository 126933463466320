define("aspen-utils/user-session/service", ["exports", "jquery", "@ember/utils", "@ember/service", "ember-cli-jwt-decode"], function (_exports, _jquery, _utils, _service, _emberCliJwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let aspenUserSessionKey = 'aspenUserSession';
  var _default = _service.default.extend({
    fetchService: (0, _service.inject)('aspen-fetch'),
    aspenStorage: (0, _service.inject)('aspen-local-storage'),
    init: function () {
      let userSession = this;
      this._super(...arguments);
      let useWindowStrategy = userSession.config.tokenStrategy !== undefined && userSession.config.tokenStrategy.toLowerCase() === 'window';
      let isDevelopment = userSession.config.environment === 'development' || userSession.config.environment === 'local';
      let useMockUser = isDevelopment && useWindowStrategy && window.aspen === undefined && userSession.config.mockUser;

      /** For development **/
      if (useMockUser) {
        window.aspen = {
          user: userSession.config.mockUser
        };
      }
      let initialUser = useWindowStrategy ? {
        token: window.aspen.user.jwt
      } : userSession.getUser();
      if (!(0, _utils.isEmpty)(initialUser) && initialUser.token) {
        userSession.authenticate(initialUser.token, initialUser.provider);
      }
      if (_jquery.default) {
        _jquery.default.ajaxPrefilter(function (options, originalOptions, xhr) {
          if (userSession.get('isAuthenticated') && !xhr.crossDomain) {
            // need to re-query for the token here, cannot use initial token variable in closure, as user may not have been authenticated the first time through
            let currentToken = userSession.getToken();
            xhr.setRequestHeader('Authorization', 'Bearer ' + currentToken);
          }
        });
      }
    },
    login: function (username, password) {
      let userSession = this;
      let fetchService = userSession.get('fetchService');
      let tokenUrl = userSession.config.identity.baseApiURL + '/token';
      let credentials = {
        username: username,
        password: password,
        grant_type: 'password'
      };
      let urlEncode = function (data) {
        let str = [];
        for (let p in data) {
          if (data.hasOwnProperty(p) && data[p]) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
          }
        }
        return str.join("&");
      };
      return fetchService.post(tokenUrl, {
        body: urlEncode(credentials),
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }).then(result => userSession.authenticate(result.data.access_token));
    },
    authenticate: function (token, provider) {
      let userSession = this;
      let decodedToken = (0, _emberCliJwtDecode.jwt_decode)(token);
      let user = {
        token: token,
        email: decodedToken.unique_name,
        exp: decodedToken.exp,
        id: decodedToken.nameid || decodedToken.name,
        provider: provider || 'Aspen',
        roles: decodedToken.role || []
      };
      userSession.set('_isAuthenticated', true);
      userSession.get('aspenStorage').setItem(aspenUserSessionKey, user);
    },
    logout: function () {
      let userSession = this;
      let logoutUrl = userSession.config.identity.baseApiURL + '/account/logout';
      let fetchService = userSession.get('fetchService');
      userSession.set('_isAuthenticated', false);
      userSession.get('aspenStorage').removeItem(aspenUserSessionKey);
      return fetchService.post(logoutUrl, {});
    },
    getUser: function () {
      let userSession = this;
      return userSession.get('aspenStorage').getItem(aspenUserSessionKey);
    },
    getToken: function () {
      let userSession = this;
      let user = userSession.getUser();
      return user ? user.token : undefined;
    },
    isAuthenticated: function () {
      let service = this;
      let user = service.getUser();
      let doesUserExist = user !== undefined && user !== null;
      if (!doesUserExist) {
        return false;
      }
      return user.exp > Date.now() / 1000;
    },
    setAssetId: function (assetId) {
      let session = this;
      session.set('assetId', assetId);
    },
    getAssetId: function () {
      let session = this;
      return session.get('assetId');
    }
  });
  _exports.default = _default;
});