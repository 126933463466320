define("@aspen/aspen-navigation-controls/components/site-navigation/navigation-mobile-menu/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AOAFu7Ng",
    "block": "[[[41,[48,[30,4]],[[[1,\"    \"],[10,0],[14,0,\"hidden-md hidden-lg\"],[12],[1,\"\\n\"],[6,[39,2],null,null,[[\"default\"],[[[[1,\"            \"],[10,1],[14,0,\"ion-navicon-round\"],[12],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,3],null,[[\"side\"],[\"right\"]],[[\"default\"],[[[[1,\"            \"],[10,0],[14,0,\"row m-x m-b\"],[12],[1,\"\\n\"],[6,[39,4],[\"index\"],[[\"class\"],[\"navbar-brand text-uppercase\"]],[[\"default\"],[[[[1,\"                    \"],[10,1],[12],[10,\"strong\"],[12],[1,[30,1]],[13],[13],[1,\"\\n\"]],[]]]]],[1,\"            \"],[13],[1,\"\\n            \"],[10,\"hr\"],[12],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"nav nav-bordered nav-stacked m-x text-uppercase\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"                    \"],[18,4,null],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"                        \"],[18,4,null],[1,\"\\n                        \"],[10,\"li\"],[14,0,\"divider\"],[12],[13],[1,\"\\n\\n                        \"],[10,\"li\"],[12],[1,\"\\n                            \"],[10,3],[14,6,\"/auth/logout\"],[12],[10,\"i\"],[14,0,\"ion-power\"],[12],[13],[1,\" Logout\"],[13],[1,\"\\n                        \"],[13],[1,\"\\n\"]],[]],[[[1,\"                        \"],[10,\"li\"],[12],[1,\"\\n                            \"],[10,3],[14,6,\"/auth/login\"],[12],[10,\"i\"],[14,0,\"ion-power\"],[12],[13],[1,\" Log In\"],[13],[1,\"\\n                        \"],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"            \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[1,[34,6]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@applicationName\",\"@isExternal\",\"@user\",\"&default\"],false,[\"if\",\"has-block\",\"side-menu-toggle\",\"side-menu\",\"side-menu-link-to\",\"yield\",\"content-backdrop\"]]",
    "moduleName": "@aspen/aspen-navigation-controls/components/site-navigation/navigation-mobile-menu/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});