define("aspen-utils/helpers/to-money", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let toMoney = function (params) {
    let amount = params[0];
    let absolute = params[1];
    let decimals = params[2] === undefined ? 2 : params[2];
    let formatted;
    if (amount === null) {
      return new _template.htmlSafe('$0.00');
    }
    if (absolute) {
      amount = Math.abs(amount);
    }
    formatted = parseFloat(amount).toFixed(decimals);
    formatted = formatted.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (amount < 0) {
      formatted = '(' + formatted.toString().replace('-', '') + ')';
    }
    return new _template.htmlSafe('$' + formatted);
  };
  var _default = (0, _helper.helper)(toMoney);
  _exports.default = _default;
});