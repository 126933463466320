define("@aspen/aspen-user-session/aspen-local-storage/service", ["exports", "@ember/service", "@aspen/aspen-user-session/cookie-storage-provider/service"], function (_exports, _service, _service2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AspenLocalStorageService extends _service.default {
    // @service( "cookie-storage-provider" ) cookies;

    constructor() {
      super();
      const service = this;
      service.cookies = new _service2.default();
      service.storageProvider = service._isLocalStorageAvailable() ? localStorage : service.cookies;
    }
    _isLocalStorageAvailable() {
      try {
        const localStorageTestKey = "local-storage-test-key";
        localStorage.setItem(localStorageTestKey, true);
        localStorage.removeItem(localStorageTestKey);
        return true;
      } catch (e) {
        return false;
      }
    }
    getItem(key) {
      const item = this.storageProvider.getItem(key);
      return item ? JSON.parse(item) : null;
    }
    setItem(key, data) {
      this.storageProvider.setItem(key, JSON.stringify(data));
    }
    removeItem(key) {
      this.storageProvider.removeItem(key);
    }
  }
  _exports.default = AspenLocalStorageService;
});