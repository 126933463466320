define('@aspen/aspen-search-controls/helpers/is-reserved-word', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.isReservedWord = isReservedWord;


    const reservedWords = ['operator', 'type'];
    function isReservedWord(params) {
        return reservedWords.indexOf(params[0]) > -1;
    }

    exports.default = Ember.Helper.helper(isReservedWord);
});