define("@aspen/aspen-utils/helpers/format-apartment", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _helper.helper)(function formatApartment(params /*, hash*/) {
    var unit = params[0];
    if (unit.buildingNumber.toLowerCase() === 'n/a') {
      return unit.apartmentNumber;
    }
    return unit.buildingNumber + ' - ' + unit.apartmentNumber;
  });
  _exports.default = _default;
});