define("aspen-utils/aspen-fetch/service", ["exports", "@ember/service", "fetch"], function (_exports, _service, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var contentTypeKey = 'Content-Type'.toLowerCase();
  var applicationJson = 'application/json'.toLowerCase();
  var accept = 'Accept'.toLowerCase();
  var _default = _service.default.extend({
    userSession: (0, _service.inject)('user-session'),
    pendingNetworkRequests: (0, _service.inject)('pending-network-requests'),
    fetch: function (input, init) {
      var service = this;
      var userSession = service.get('userSession');
      var pendingNetworkRequests = service.get('pendingNetworkRequests');
      var token = userSession.getToken();
      var responseStrategies = {
        'default': function (response) {
          return response.text().then(function (text) {
            if (!response.ok) {
              return Promise.reject({
                error: text,
                response: response
              });
            }
            return {
              data: text ? JSON.parse(text) : {},
              response: response
            };
          });
        },
        'blob': function (response) {
          if (response.ok) {
            return response.blob().then(function (blob) {
              return {
                data: blob,
                response: response
              };
            });
          }
          return response.text().then(function (text) {
            return Promise.reject({
              error: text ? JSON.parse(text) : {},
              response: response
            });
          });
        }
      };
      var parseResponse = function (response) {
        var responseStrategy = responseStrategies[init.responseType];
        pendingNetworkRequests.decrement();
        if (responseStrategy) {
          return responseStrategy(response);
        }
      };
      init = init || {
        method: 'GET'
      };
      init.responseType = init.responseType || 'default';
      init.headers = init.headers || {};
      init.headers[contentTypeKey] = init.headers[contentTypeKey] || applicationJson;
      init.headers[accept] = init.headers[accept] || applicationJson;
      if (!init.suppressAuthorizationHeader && token) {
        init.headers['Authorization'] = 'Bearer ' + token;
      }
      if (init.body && init.headers[contentTypeKey] === applicationJson) {
        init.body = JSON.stringify(init.body);
      }
      pendingNetworkRequests.increment();
      return (0, _fetch.default)(input, init).then(parseResponse);
    },
    post: function (url, init) {
      var service = this;
      init.method = 'POST';
      return service.fetch(url, init);
    },
    put: function (url, init) {
      var service = this;
      init.method = 'PUT';
      return service.fetch(url, init);
    },
    patch: function (url, init) {
      var service = this;
      init.method = 'PATCH';
      return service.fetch(url, init);
    },
    'delete': function (url) {
      var service = this;
      return service.fetch(url, {
        method: 'DELETE'
      });
    }
  });
  _exports.default = _default;
});