define("aspen-utils/pending-network-requests/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    networkRequests: 0,
    eventBus: (0, _service.inject)('aspen-event-bus'),
    increment: function () {
      var service = this;
      service.set('networkRequests', service.networkRequests + 1);
      service.get('eventBus').publish('onNetworkRequest', service.networkRequests);
    },
    decrement: function () {
      var service = this;
      service.set('networkRequests', service.networkRequests - 1);
      service.get('eventBus').publish('onNetworkRequest', service.networkRequests);
    }
  });
  _exports.default = _default;
});