define("@aspen/aspen-form-controls/components/text-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "moment"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="input-group input-group-outline my-3 {{if (not (is-empty this.value)) "is-filled" "" }} {{if
          (get @changeset.error @property) "is-invalid"}} {{if this._isFocused "is-focused" ""}} {{if @isReadOnly
                                                                                                      "is-disabled"}}">
      {{#if @label}}
          <label for={{concat "text-" @property}}
                 class="form-label {{if @required "required" ""}}">
              {{@label}}
          </label>
      {{/if}}
  
      <input {{on "input" this.updateValue}}
          {{on "focusin" (fn this.onFocus true)}}
          {{on "focusout" (fn this.onFocus false)}}
              readonly={{@isReadOnly}}
              disabled={{@isReadOnly}}
              class="form-control"
              type={{if @type @type "text"}}
              id={{concat "text-" @property}}
              value={{this.value}}
              placeholder={{@placeholder}}>
  
      {{#if @showErrorMessage}}
          <em
                  style={{if @errorStyle @errorStyle ''}}
                  class={{if @errorClass @errorClass 'control-label text-danger error-message'}}
          >
              {{get @changeset.error (concat @property (if @errorValidation @errorValidation '.validation.0'))}}
          </em>
      {{/if}}
  </div>
  
  */
  {
    "id": "e6uRPPB/",
    "block": "[[[10,0],[15,0,[29,[\"input-group input-group-outline my-3 \",[52,[28,[37,1],[[28,[37,2],[[30,0,[\"value\"]]],null]],null],\"is-filled\",\"\"],\" \",[52,[28,[37,3],[[30,1,[\"error\"]],[30,2]],null],\"is-invalid\"],\" \",[52,[30,0,[\"_isFocused\"]],\"is-focused\",\"\"],\" \",[52,[30,3],\"is-disabled\"]]]],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,\"label\"],[15,\"for\",[28,[37,4],[\"text-\",[30,2]],null]],[15,0,[29,[\"form-label \",[52,[30,5],\"required\",\"\"]]]],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[11,\"input\"],[16,\"readonly\",[30,3]],[16,\"disabled\",[30,3]],[24,0,\"form-control\"],[16,1,[28,[37,4],[\"text-\",[30,2]],null]],[16,2,[30,0,[\"value\"]]],[16,\"placeholder\",[30,7]],[16,4,[52,[30,6],[30,6],\"text\"]],[4,[38,5],[\"input\",[30,0,[\"updateValue\"]]],null],[4,[38,5],[\"focusin\",[28,[37,6],[[30,0,[\"onFocus\"]],true],null]],null],[4,[38,5],[\"focusout\",[28,[37,6],[[30,0,[\"onFocus\"]],false],null]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,8],[[[1,\"        \"],[10,\"em\"],[15,5,[52,[30,9],[30,9],\"\"]],[15,0,[52,[30,10],[30,10],\"control-label text-danger error-message\"]],[12],[1,\"\\n            \"],[1,[28,[35,3],[[30,1,[\"error\"]],[28,[37,4],[[30,2],[52,[30,11],[30,11],\".validation.0\"]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@changeset\",\"@property\",\"@isReadOnly\",\"@label\",\"@required\",\"@type\",\"@placeholder\",\"@showErrorMessage\",\"@errorStyle\",\"@errorClass\",\"@errorValidation\"],false,[\"if\",\"not\",\"is-empty\",\"get\",\"concat\",\"on\",\"fn\"]]",
    "moduleName": "@aspen/aspen-form-controls/components/text-input.hbs",
    "isStrictMode": false
  });
  let TextInputComponent = (_class = class TextInputComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_isFocused", _descriptor, this);
    }
    get value() {
      const component = this;
      const changesetValue = component.args.changeset.get(component.args.property);
      return component.args.type === "date" ? changesetValue ? (0, _moment.default)(changesetValue).format("YYYY-MM-DD") : undefined : changesetValue;
    }
    updateValue(event) {
      const component = this;
      component.args.changeset.set(component.args.property, event.target.value);
      if (component.args.onInput) {
        component.args.onInput(event.target.value);
      }
    }
    onFocus(isFocused) {
      this._isFocused = isFocused;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_isFocused", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocus"), _class.prototype)), _class);
  _exports.default = TextInputComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextInputComponent);
});