define("aspen-utils/helpers/format-date", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var formatDate = function (params /*, hash*/) {
    var date = params[0];
    var format = params[1] || 'LL';
    var utc = params[2];
    if (!date) {
      return '';
    }
    return utc ? moment(date).utc().format(format) : moment(date).format(format);
  };
  var _default = (0, _helper.helper)(formatDate);
  _exports.default = _default;
});