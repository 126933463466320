define('@aspen/aspen-search-controls/components/aspen-search/component', ['exports', '@aspen/aspen-search-controls/components/aspen-search/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const reservedWords = ['operator', 'type'];

    exports.default = Ember.Component.extend({
        layout: _template.default,

        searchService: Ember.inject.service('search'),

        showFacets: true,
        defaultSearchSize: 50,

        init: function () {
            let component = this;
            // Ember.assign doesn't even follow it's own ridiculous rules about setting basic properties using 'set', so have to do it manually (this functionality broke all of a sudden, and randomly).
            let emberAssignIsAMess = function () {
                let defaultQuery = { top: component.query.top || component.defaultSearchSize, skip: 0 };

                if (!component.query) {
                    return defaultQuery;
                }

                Object.keys(defaultQuery).forEach(k => {
                    Ember.set(component.query, k, defaultQuery[k]);
                });

                return component.query;
            };

            this._super(...arguments);

            component.set('query', emberAssignIsAMess());
            component.set('searchTerm', component.searchTerm || '');
            component.set('selectedFilter', component.selectedFilter || {});
            component.set('results', component.results || []);
            component.set('facets', component.facets || []);
            component.set('placeholderText', component.placeholderText || 'Search');

            this.search(true);
        },

        showingFrom: Ember.computed('query', function () {
            let component = this;
            return !component.query.count ? 0 : parseInt(component.query.skip || 0) + 1;
        }),

        showingTo: Ember.computed('query', function () {
            let component = this;
            let to = parseInt(component.query.skip || 0) + parseInt(component.query.top || component.defaultSearchSize);
            return to < component.query.count ? to : component.query.count;
        }),

        search: function (updateFacets) {
            let component = this;
            let searchService = component.get('searchService');

            return searchService.execute(component.indexName, component.searchTerm, component.facets.map(f => f.key).join(','), component.selectedFilter, component.query).then(response => {

                if (component.sortByFacetValue) {
                    const compareFacetValue = ({ value: a }, { value: b }) => {
                        if (a > b) {
                            return 1;
                        }
                        if (a < b) {
                            return -1;
                        }
                        return 0;
                    };
                    Object.values(response.data.filter.facets).forEach(values => {
                        values.sort(compareFacetValue);
                    });
                }

                if (updateFacets) {
                    component.set('query', response.data.filter);
                } else {
                    //Not sure about this...really just want to update counts but not facets
                    component.set('query', Ember.assign(response.data.filter, { facets: component.query.facets }));
                }

                component.set('results', response.data.items);

                if (component.onPostSearch) {
                    component.onPostSearch(component.results);
                }
            });
        },

        actions: {
            search: function () {
                let component = this;
                Ember.set(component.query, 'skip', 0);
                return component.search(true);
            },

            clearSearch: function () {
                let component = this;

                if (component.onClear) {
                    return component.onClear().then(() => component.search(true));
                } else {
                    component.set('searchTerm', '');
                    component.set('selectedFilter', {});
                    return component.search(true);
                }
            },

            updateQueryFacet: function (field, value) {
                let component = this;
                let selectedFilter = component.selectedFilter;

                if (!selectedFilter[field]) {
                    Ember.set(selectedFilter, field, {});
                }

                if (selectedFilter[field].operator === 'ne') {
                    const reservedWordsSet = new Set(reservedWords);
                    Ember.set(selectedFilter, `${field}.operator`, 'eq');
                    Object.keys(selectedFilter[field]).filter(facetValue => !reservedWordsSet.has(facetValue)).forEach(facetValue => Ember.set(selectedFilter, `${field}.${facetValue}`, undefined));
                }

                Ember.set(selectedFilter[field], value, !selectedFilter[field][value]);

                Ember.set(component.query, 'skip', 0);

                return component.search(component.updateFacetListAfterSelection || false);
            },

            updateOrderBy: function (orderBy) {
                let component = this;

                Ember.set(component.query, 'orderby', orderBy);
                Ember.set(component.query, 'skip', 0);

                return component.search(component.updateFacetListAfterSelection || false);
            },

            updateTop: function (top) {
                let component = this;

                Ember.set(component.query, 'top', top);

                return component.search(component.updateFacetListAfterSelection || false);
            },

            nextPage: function () {
                let component = this;
                let nextSkip = parseInt(component.query.skip) + parseInt(component.query.top);

                Ember.set(component.query, 'skip', nextSkip < component.query.count ? nextSkip : parseInt(component.query.count) - parseInt(component.query.top));
                component.search(false).then(() => {
                    if (component.onPageChange) {
                        component.onPageChange();
                    }
                });
            },

            previousPage: function () {
                let component = this;
                let nextSkip = parseInt(component.query.skip) - parseInt(component.query.top);

                Ember.set(component.query, 'skip', nextSkip >= 0 ? nextSkip : 0);
                component.search(false).then(() => {
                    if (component.onPageChange) {
                        component.onPageChange();
                    }
                });
            },

            firstPage: function () {
                let component = this;

                Ember.set(component.query, 'skip', 0);
                component.search(false).then(() => {
                    if (component.onPageChange) {
                        component.onPageChange();
                    }
                });
            },

            lastPage: function () {
                let component = this;
                let totalPages = Math.ceil(parseInt(component.query.count) / parseInt(component.query.top));
                let skip = (totalPages - 1) * parseInt(component.query.top);

                Ember.set(component.query, 'skip', skip);
                component.search(false).then(() => {
                    if (component.onPageChange) {
                        component.onPageChange();
                    }
                });
            },

            changePage: function (value) {
                let component = this;
                let skip = (value - 1) * parseInt(component.query.top);

                Ember.set(component.query, 'skip', skip);
                component.search(false).then(() => {
                    if (component.onPageChange) {
                        component.onPageChange();
                    }
                });
            }
        }
    });
});