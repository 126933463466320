define("aspen-utils/utils/conditional-validation", ["exports", "@ember/object", "@ember/utils", "@ember/array"], function (_exports, _object, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContains = arrayContains;
  _exports.default = validateConditional;
  _exports.empty = empty;
  _exports.equal = equal;
  _exports.hasValue = hasValue;
  _exports.notEqual = notEqual;
  _exports.prop = prop;
  function validateConditional(options) {
    let thenValidators = (0, _array.makeArray)(options.then);
    let elseValidators = (0, _array.makeArray)(options.else);
    let max = Math.max(thenValidators.length, elseValidators.length);

    // A single validator can't return an array of validations,
    // so we return an array of validators instead :/
    let validators = [];
    for (let i = 0; i < max; i++) {
      validators.push(makeValidator((0, _array.makeArray)(options.if), thenValidators[i], elseValidators[i]));
    }
    return validators;
  }
  function makeValidator(conditions, thenValidator, elseValidator) {
    return (key, newValue, oldValue, changes, content) => {
      const conditionPassed = conditions.every(c => c(key, newValue, oldValue, changes, content));
      const validator = conditionPassed ? thenValidator : elseValidator;
      return !validator || validator(key, newValue, oldValue, changes, content);
    };
  }
  function arrayContains(name, expected) {
    return prop(name, value => {
      return value.find(element => (0, _utils.isEqual)(element, expected));
    });
  }
  function prop(name, callback) {
    return (key, newValue, oldValue, changes, content) => {
      let value = changes.hasOwnProperty(name) ? changes[name] : (0, _object.get)(content, name);
      return callback(value);
    };
  }
  function equal(name, expected) {
    return prop(name, value => value === expected);
  }
  function notEqual(name, expected) {
    return prop(name, value => value !== expected);
  }
  function hasValue(name, expected) {
    return prop(name, value => expected.indexOf(value) > -1);
  }
  function empty(name) {
    return prop(name, value => (0, _utils.isEmpty)(value));
  }
});