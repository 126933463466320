define("aspen-utils/helpers/from-now", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fromNow = fromNow;
  function fromNow(params /*, hash*/) {
    var date = params[0];
    var utc = params[2] || false;
    if (utc) {
      date = moment.utc(date).toDate();
    }
    return moment(date).fromNow();
  }
  var _default = (0, _helper.helper)(fromNow);
  _exports.default = _default;
});