define("@aspen/aspen-utils/helpers/normalize", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.normalize = normalize;
  // Remove dashes and capitalize the first letter of each word.

  function normalize(value) {
    if (value && value.length > 0) {
      let val = value[0];
      return val.replace(/-/g, ' ').replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    return value;
  }
  var _default = (0, _helper.helper)(normalize);
  _exports.default = _default;
});