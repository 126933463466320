define("@aspen/aspen-form-controls/services/validated-component", ["exports", "@ember/service", "ember-changeset", "ember-changeset-validations"], function (_exports, _service, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ValidatedComponentService extends _service.default {
    initChangeset(changeset, validation) {
      return new _emberChangeset.default(changeset, (0, _emberChangesetValidations.default)(validation), validation);
    }
    async validateAndExecuteChanges(changeset) {
      const snapshot = changeset.snapshot();
      try {
        await changeset.validate();
        if (changeset.get('isValid')) {
          changeset.execute();
          return true;
        }
      } catch (e) {
        changeset.restore(snapshot);
        throw new Error(e);
      }
      throw new Error('Changeset is not valid');
    }
  }
  _exports.default = ValidatedComponentService;
});