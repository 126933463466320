define("@aspen/aspen-navigation-controls/components/site-navigation/navigation-menu/component", ["exports", "@ember/component", "@aspen/aspen-navigation-controls/components/site-navigation/navigation-menu/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _template.default
  });
  _exports.default = _default;
});