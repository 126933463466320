define("@aspen/aspen-utils/helpers/camelize", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelize = camelize;
  _exports.default = void 0;
  function camelize(value) {
    let val = value[0];
    return val.camelize();
  }
  var _default = (0, _helper.helper)(camelize);
  _exports.default = _default;
});