define("aspen-utils/mixins/authenticated-route", ["exports", "@ember/application", "@ember/service", "@ember/object/mixin"], function (_exports, _application, _service, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _mixin.default.create({
    userSession: (0, _service.inject)('user-session'),
    beforeModel: function () {
      var route = this;
      var userSession = route.get('userSession');
      var config = (0, _application.getOwner)(route).resolveRegistration('config:environment');
      var redirectUri = route.get('router.url');
      if (!route.authenticationRoute && !config.authenticationRoute) {
        throw new Error('Must provide authenticationRoute configuration entry, or override in route');
      }
      if (userSession.isAuthenticated()) {
        return route._super(...arguments);
      } else {
        return route.transitionTo(route.authenticationRoute || config.authenticationRoute, {
          queryParams: {
            redirectUri: encodeURIComponent(redirectUri)
          }
        });
      }
    }
  });
  _exports.default = _default;
});