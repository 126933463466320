define('@aspen/aspen-search-controls/search/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        fetchService: Ember.inject.service('aspen-fetch'),

        execute: function (indexName, searchTerm, facets, filter, query) {
            let service = this;
            let fetchService = service.get('fetchService');
            let top = query.top;
            let skip = query.skip;
            let orderBy = query.orderby || '';

            return fetchService.fetch(`${service.config.search.baseApiURL}/search/${indexName}?search=${searchTerm ? encodeURIComponent(searchTerm) : ''}&facets=${facets}&top=${top}&skip=${skip}&filter=${service.buildFilter(filter)}&orderby=${orderBy}&facetCount=100`);
        },

        buildFilter: function (filter) {
            let queryFilter = Object.keys(filter).map(facetName => {
                let facetFilter = filter[facetName];
                let { operator = 'eq', type = 'Edm.String' } = facetFilter;
                //backwards compatibility
                type = type === 'collection' ? 'Collection(Edm.String)' : type;
                let buildClause = facetValue => {
                    if (type === 'Collection(Edm.String)') {
                        return facetFilter[facetValue] ? `${facetName}/any(x: search.in(x, '${facetValue}', '|'))` : '';
                    } else if (type === 'Edm.String') {
                        return facetFilter[facetValue] ? `${facetName} ${operator} '${encodeURIComponent(facetValue)}'` : '';
                    } else {
                        return facetFilter[facetValue] ? `${facetName} ${operator} ${encodeURIComponent(facetValue)}` : '';
                    }
                };
                let byReservedKeys = k => k !== 'type' && k !== 'operator';
                let intraFacetClause = Object.keys(facetFilter).filter(byReservedKeys).map(buildClause).filter(v => v).join(' or ');

                return intraFacetClause ? `(${intraFacetClause})` : intraFacetClause;
            }).filter(v => v).join(' and ');

            console.log(queryFilter);

            return queryFilter;
        }
    });
});