define("aspen-utils/helpers/dasherize", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dasherize = dasherize;
  _exports.default = void 0;
  function dasherize(value) {
    let val = value[0];
    return val.dasherize();
  }
  var _default = (0, _helper.helper)(dasherize);
  _exports.default = _default;
});